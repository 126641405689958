@charset "utf-8";
/******* This is the CSS styles for the Grow App. Please note that the app's default code is presented for smaller screens such as tablets and phones. Desktop is considered the least important view screen and thus specific desktop based styles are at the very bottom of this document.*******/



/******* UNIVERSAL STYLES  *******/
/******* (Styles that are the same/uniform across all pages.) *******/

body {
  padding: 0;
  margin: 0;
}

a {
  color: #6c308d;
  text-decoration: underline;
}
a:hover {
  color: #6c308d;
  text-decoration: underline !important;
}

i {
  font-size: calc(3.5rem + 1.5vw);
}

p,
ol,
ul {
  font-size: calc(.8rem + 1vw) !important;
  font-weight: 400;
  font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
}

h1 {
  font-size: calc(2rem + 1vw);
  max-width: 80%;
  margin: 50px 0;
  font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
}

h2 {
  font-size: calc(1.5rem + 1vw);
  margin: 30px 0;
  font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
}

h3 {
  font-size: calc(1rem + 1vw);
  font-weight: 700;
  margin: 30px 0;
  font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
}

ol > li::marker {
  font-weight: bold;
}

ul {
  list-style: none;
  margin: 50px 0;
}

ul li::before {
  content: "\2726";
  color: #2da49e;
  font-weight: bold;
  display: inline-block;
  width: 2em;
  margin-left: -2em;
}
table {
  font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
  width: 100%;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.desktop-show {
  display: none;
}
.link-text-white {
  color: #fff !important;
}
.img-responsive {
  width: 100%;
  padding: 0;
}

.align-right {
  float: right;
}

.center-button {
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.minus-5-top {
  margin-top: -5px;
}
/******* HEADER AND NAVIGATION STYLES *******/
/******* (Styles that deal with the shape/look of the header and the navigation. This is mainly the base tablet/phone styles. Desktop styles are at the very bottom of the stylesheet.) *******/

.logo-area { /*** Area for people to click on for home link ***/
  width: 40%;
  height: 100px;
  float: left;
}

.header-area {
  background: #007074;
  background-image: url(/src/Media/Grow-app-top-1.png); /*** Actual logo piece here ***/
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 109px;
}

.header-area i {
  position: absolute;
  top: 20px;
  right: 5%;
  color: #ffffff;
  font-size: 2em !important;
}

.navigation-menu {
  border-top: 2px #1a1a1a solid;
  border-bottom: 2px #1a1a1a solid;
  background: #322d35;
  color: #ffffff !important;
}

.navigation-menu ul li{
  padding: 20px 40px;
	
}
.navigation-menu ul li::before{
  content: " " !important;
}
.navigation-menu a {
  color: #ffffff !important;
  text-decoration: none;
  font-size: calc(1rem + 0.8vw) !important;
}

.page-header {
  color: #ffffff;
  background: #007074 !important;
  margin-top: -52px;
}

.page-header h1 {
  padding: 25px 5% 0 5%;
  max-width: 100% !important;
}
.eraser-line {
    width: 100%;
    height: 20px;
    background: #ffffff;
    margin-top: -10px;
    position: absolute;
    z-index: 0;
}
div.map li {
  all: unset;
}
/******* HOMEPAGE STYLES *******/
/******* (These styles are primarily used on the homepage and nowhere else on the site.) *******/
.home-header-area {
	position: relative;
	z-index: -1;
}
.background-section-1 {
  color: #310c33;
  background: #aee9e7;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(166, 231, 229, 1) 100%
  );
  background: -o-linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(166, 231, 229, 1) 100%
  );
  background: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(166, 231, 229, 1) 100%
  );
  transform: scale(1);
  padding-bottom: 100px;
}

.section-1-white {
  z-index: 2;
  position: relative;
  margin-top: -150px;
  background: #ffffff;
  clip-path: polygon(0 60%, 100% 0, 100% 100%, 0% 100%);
  padding: 5%;
  min-height: 200px;
}

.section-2-transparent-white {
  z-index: 2;
  position: relative;
  margin-top: -200px;
  background: #ffffff;
  clip-path: polygon(0 0, 100% 65%, 100% 100%, 0% 100%);
  padding: 5%;
  min-height: 200px;
  opacity: 0.5;
}

.safety-info-area-title {
  width: 100%;
  padding: 5%;
  z-index: 10;
  margin-top: -180px;
}
.safety-info-area-title h2 {
  font-size: calc(1rem + 1.5vw) !important;
  max-width: 100%;
}

.safety-area {
  background: #fff;
  width: 100%;
  position: relative;
  margin-top: -50px;
}

.content-area-1-row {
  margin: 50px 0 8% 0;
  text-align: center;
}

.content-area-1-row a {
  text-decoration: none;
}
.content-area-1-row i {
  -webkit-text-stroke: 3px #6c308d;
  color: transparent !important;  
  margin-bottom: 20px;
  font-size: calc(2.5rem + 1.5vw) !important;
}
.content-area-1-row div {
  padding: 0 2%;
  flex-grow: 1;
}
.icon-dark-outline {
  color: #1a1a1a;
}

.icon-white-outline {
  color: #fff !important;
}

.white-block-top {
  background: #fff;
  height: 100px;
}

.professionals-header-area {
	transform: scale(1);
}
.professionals-header {
  text-align: right;
  position: relative;
  width: 100%;
  margin: -54px 5% 0 -5%;
  background: #ffffff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  z-index: 10;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -13px;
}
.professionals-area {
  background-color: #007074 !important;
  color: #ffffff;
  margin-top: -40px;
  width: 100%;
  padding: 15% 5%;
  z-index: 8;
}
.content-area-services {
  width: 100%;
  padding: 12% 5%;
  z-index: 9;
  background: #322d35 !important;
}
.content-area-education .small-text {
  margin-top: -29px;
  font-size: 1.5em;
}
.icon-overlap-spacing {
  margin-top: 45px;
}
.content-area-education {
  background: #f3f3f6 !important;
  width: 100%;
  padding: 5%;
  z-index: 8;
  min-height: 600px;
}
.tips-area {
  width: 100%;
  padding: 12% 5% 5% 5%;
  z-index: 9;
  background: #ffffff;
  clip-path: polygon(15% 5%, 100% 5%, 100% 100%, 0 100%, 0 0);
  margin-top: -50px;
}
.tips-header {
	font-size: calc(1.5rem + 1.5vw) !important;
	max-width: 100% !important;
}
.handshake-icon {
  position: relative;
  z-index: 10;
  display: block;
  margin-top: 60px;
  margin-bottom: -40px;
}

.information-area {
  width: 100%;
  padding: 10% 5%;
  z-index: 10;
  background: rgb(59, 192, 177);
  background: linear-gradient(
    0deg,
    rgba(59, 192, 177, 1) 0%,
    rgba(39, 178, 155, 1) 50%,
    rgba(0, 112, 116, 1) 100%
  );
  color: #ffffff !important;
  margin-top: -70px;
  min-height: 400px;
}

.about-area {
  background: #9addd4;
  background-image: url(/src/Media/Grow-about-background.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 20% 5% 5% 5%;
}

.flex-content {
  display: flex;
  justify-content: center;
}

.home-button-clear {
  background-color: rgba(54, 42, 61, 0.6);
  border: 3px #ffffff solid;
  padding: 5%;
  margin: 3%;
  min-width: 47%;
  position: relative;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
a.home-button-clear {
  color: #ffffff !important;
  text-decoration: none;
}
.home-button-clear p {
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 0;
}
.home-button-clear i {
  font-size: calc(3.5rem + 1.5vw) !important;
  position: absolute;
  top: -60px;
}

.home-button-clear h3 {
  font-size: calc(.8rem + 1vw);
	margin: 5%;
}
.home-button-clear p.small-text {
  font-size: calc(.8rem + 0.3vw) !important; /*font-size: calc(.5rem + 0.3vw) !important;*/
	margin-top: 0 !important;
}

.download-button-clear {
  background-color: rgba(54, 42, 61, 0.6);
  border: 3px #ffffff solid;
  padding: 3%;
  margin: 3%;
  min-width: 47%;
  position: relative;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
a.download-button-clear {
  color: #ffffff !important;
  text-decoration: none;
}
.download-button-clear p {
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 0;
}
.download-button-clear i {
  font-size: calc(3.5rem + 1.5vw) !important;
  position: absolute;
  top: -60px;
}

.download-button-clear h3 {
  font-size: calc(.8rem + 1vw);
	margin: 5%;
}
.download-button-clear p.small-text {
  font-size: calc(.8rem + 0.3vw) !important; /*font-size: calc(.5rem + 0.3vw) !important;*/
	margin-top: 0 !important;
}

.margin-50 {
  margin: 20px 0 50px 0;
}

.home-button-gradient {
  margin: 4% 0;
  color: #ffffff !important;
  padding: 5%;
  text-align: center;
  background: rgb(50, 45, 53);
  background: linear-gradient(
    153deg,
    rgba(50, 45, 53, 1) 0%,
    rgba(0, 112, 116, 1) 100%
  );
  width: 100%;
  border-radius: 20px;
}

.home-button-gradient a {
  color: #ffffff !important;
  text-decoration: none;
}

.home-button-gradient p {
  font-weight: 700 !important;
  margin-bottom: 0;
  font-size: calc(.8rem + 1vw) !important; /*font-size: calc(.5rem + 1vw) !important;*/
}

.homepage-footer {
  color: #1a1a1a;
  padding: 3% 5% 0% 5%;
  margin: 10px 0;
}
.homepage-footer h2 {
  display: inline-block;
  max-width: 100%;
}
.homepage-footer a {
  display: inline-block;
}
/******* SECONDARY PAGE STYLES *******/
/******* (These styles are used on the secondary pages on the site. This also includes a lot of optional 'featured areas' that can be used to break up the text flow, put focus on important content, and just enhanse the over all experience.) *******/

.main-content { /*** This is where all the main writing goes. This and the 'colorful-spacer-box' are both 100% width blocks. Do not nest them. ***/
  padding: 60px 5%;
  margin-top: -2px;
  z-index: 10;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.main-content p,
ol,
ul {
  color: #4e5656;
}

.main-content h2 {
  color: #007074;
  font-weight: 700;
}

.main-content h3 {
  color: #007074;
}

.featured-box {
  padding: 5%;
  margin: 60px 0;
}
.featured-box-green {
  background: rgb(43, 183, 154);
  background: linear-gradient(
    131deg,
    rgba(0, 112, 116, 1) 0%,
    rgba(108, 215, 208, 1) 100%
  );
  border: 10px #007074 solid;
}

.featured-box-dark {
  background: #322d35 !important;
}
.featured-box-dark p {
  color: #ffffff !important;
}
.featured-box-dark ol {
  color: #ffffff !important;
}
.featured-box-dark ul {
  color: #ffffff !important;
}

.light-background {/*** A general color style rgar can be applied to any box or area. Example apply to: 'colorful-spacer-box', 'featured-box', 'main-content' ***/
  background: #f3f3f6 !important;
  color: #322d35 !important;
}
.dark-background {/*** A general color style rgar can be applied to any box or area. Example apply to: 'colorful-spacer-box', 'featured-box', 'main-content' ***/
  background: #322d35 !important;
  color: #ffffff;
}
.dark-background h2 {
  color: #ffffff;
}
.dark-background h3 {
  color: #ffffff;
}
.dark-background p {
  color: #ffffff;
}
.dark-background ul {
  color: #ffffff;
}
.dark-background ol {
  color: #ffffff;
}
.green-background {/*** A general color style rgar can be applied to any box or area. Example apply to: 'colorful-spacer-box', 'featured-box', 'main-content' ***/
  background: rgb(43, 183, 154);
  background: linear-gradient(
    131deg,
    rgba(0, 112, 116, 1) 0%,
    rgba(108, 215, 208, 1) 100%
  );
}

.button-gradient {
  background: rgb(50, 45, 53);
  background: linear-gradient(
    153deg,
    rgba(50, 45, 53, 1) 0%,
    rgba(0, 112, 116, 1) 100%
  );
}
.colorful-spacer-box {/*** This is a 100% width spacer block for visual interest/design. Do not nest inside the 'main-content' block. ***/
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  max-height: 250px;
  min-height: 150px;
  position: relative;
  margin-bottom: -120px;
  z-index: -1;
}
.colorful-spacer-box-connect {
  width: 100%;
  height: 20px;
  background: #ffffff;
  margin-top: 10px;
  margin-top: 100px;
  position: absolute;
  z-index: -1;
}
.colorful-spacer-box p {
  transform: rotate(-40deg);
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: calc(2.5rem + 2vw) !important;
  font-weight: 700;
  height: auto;
  width: 120%;
  margin-top: -200px;
  margin-left: 100px;
}
.transparent-text {
  visibility: hidden;
}

.colorful-spacer-box-wave {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: block;
  padding: 0;
}

.secondary-footer {
  position: relative;
  background: rgb(43, 183, 154);
  background: linear-gradient(
    131deg,
    rgba(0, 112, 116, 1) 0%,
    rgba(108, 215, 208, 1) 100%
  );
  color: #ffffff !important;
  padding: 3% 5%;
}
.secondary-footer h2 {
  max-width: 100%;
}
/******* MEDIA STYLES *******/
/******* Since the site is mainly geared to phones and tablets, these are the few styles that allow for a better experience all around look. *******/
@media only screen and (min-width: 700px) { /*Changed this from 7 to 6*/
  .background-section-1 {
    min-height: 350px;
  }
.professionals-header {
  background-position-y: 65%;
}
}

@media only screen and (min-width: 980px) {

  .desktop-hide {
    display: none;
  }
	.desktop-show {
		display: block;
	}
  .safety-info-area-title {
    padding: 0%
  }
  .professionals-area {
    padding: 5% 5%;
  }
  .content-area-services {
    padding: 5% 5%;
  }
  .tips-area {
    padding: 5% 5%;
  }
  .information-area {
    padding: 10% 5% 0% 5%;
  }
}
@media only screen and (min-width: 1000px) {
  .colorful-spacer-box {
    max-height: 400px;
    min-height: 350px;
  }  
}
@media only screen and (min-width: 1800px) {
  .colorful-spacer-box {
    max-height: 700px;
    min-height: 400px;
  }  
}
